import { useCallback, useEffect, useRef } from 'react'

export interface EventSourceMessage {
  data: string
}

export interface EventSourceHook {
  close: () => void
  start: (uri: string, setData: (data: EventSourceMessage) => void, setError: (error: Event) => void) => void
}

export const useEventSource = (): EventSourceHook => {
  const eventSourceRef = useRef<EventSource | null>(null)

  const closeEventSource = useCallback(() => {
    eventSourceRef.current?.close()
  }, [])

  const start = useCallback(
    (sseUri: string, setData: (data: EventSourceMessage) => void, setError: (error: Event) => void) => {
      // Close the existing connection when the URI changes
      closeEventSource()

      // Check if the URI is valid before creating a new EventSource
      if (sseUri && (!eventSourceRef.current || sseUri !== eventSourceRef.current.url)) {
        const es = new EventSource(sseUri)
        eventSourceRef.current = es

        es.onmessage = event => {
          setData(event)
        }

        es.onerror = event => {
          setError(event)
          closeEventSource()
        }

        // Return the cleanup function that closes the EventSource
        // when the component unmounts or the URI changes
        return () => {
          es.close()
        }
      }
    },
    [closeEventSource],
  )

  useEffect(() => {
    return () => {
      closeEventSource()
    }
  }, [closeEventSource])
  return { close: closeEventSource, start }
}
