import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { setEventSource } from '../helpers.ts'
import GlobalContext from './GlobalContext.tsx'
import MyMarkdown from './Markdown.tsx'
const host = window.location.hostname.includes('localhost')
  ? 'http://localhost:3000'
  : 'https://zuericitygpt-api.gpt.liip.ch'

export const ResultErz = (props: { erzQuery: { query: string; function: string } | null; userId: string | null }) => {
  const [sseUri, setSseUri] = useState('')
  const { t } = useTranslation()
  const [infoResult, setInfoResult] = useState<string>('')
  const { mainLanguage, org } = useContext(GlobalContext)

  useEffect(() => {
    if (props.erzQuery) {
      setSseUri(
        `${host}/${org}/assistant/sse?function=${encodeURIComponent(
          props.erzQuery?.function,
        )}&query=${encodeURIComponent(props.erzQuery?.query)}&key=X9hL4Gp5W2D7eRtF&r=${Math.random()}${
          props.userId ? `&uID=${props.userId}` : ''
        }&lang=${mainLanguage}`,
      )
    }
  }, [mainLanguage, org, props.erzQuery, props.userId])

  useEffect(() => {
    return setEventSource(
      sseUri,
      t,
      () => {},
      () => {},
      setInfoResult,
    )
  }, [t, sseUri])

  return (
    <>
      <p>
        <em>Alternative Antwort mit Daten von offenen APIs (OpenERZ oder timetable.search.ch):</em>
      </p>
      <div translate="no">
        <MyMarkdown markdown={infoResult}></MyMarkdown>
      </div>
      <hr />
    </>
  )
}
